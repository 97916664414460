import React from 'react'

import Layout from '@components/layout/Layout'
import TopLink from '@components/top-link/top-link.component'
import ArchivedEventCard from '@components/archived-event-card/archived-event-card.component'
import { videoLists } from '@components/video-library/videos'
import { presenters } from '@components/archived-event-card/presenters'

import narcolepsyBrainImg from '@assets/images/narcolepsy-pathophysiology.png'

import './archived-events.scss'


const ArchivedEvents = ({ location }) => {
  const videoClass = { description: 'description' }
  const videoTitleColon = {
    colon: ''
  }
  const bridging = {
    title1: 'Narcolepsy: Bridging the Gap Between Science and Clinical Practice',
    video: videoLists.archivedEvents[0],
    imageAlt: videoLists.archivedEvents[0].imageAlt,
    date: 'January 2013',
    content1: (
      <div>
        The initial satellite broadcast event,{' '}
        <i>Narcolepsy: Bridging the Gap Between Science and Clinical Practice,</i> took place in January 2013. View the
        archived event in its entirety here.
      </div>
    ),
    title2: 'The learning objectives for this program were:',
    content2: (
      <ul>
        <li>
          <span>
            Develop a critical understanding of the multiple factors that affect symptom presentation in patients with
            narcolepsy
          </span>
        </li>
        <li>
          <span>
            Describe the pathophysiology of narcolepsy and integrate basic pathophysiologic concepts with clinical
            reasoning and decision-making
          </span>
        </li>
        <li>
          <span>
            Describe the neurobiologic links among the symptoms of narcolepsy, including excessive daytime sleepiness,
            cataplexy, disturbed nocturnal sleep, hypnagogic hallucinations, and sleep paralysis
          </span>
        </li>
        <li>
          <span>
            Discuss incorporating clinical best practices, including interview skills and screening tools, into clinical
            practice to improve recognition, screening, and diagnosis of patients with narcolepsy
          </span>
        </li>
      </ul>
    ),
    presenter1: presenters.thomasRoth,
    presenter2: presenters.jonathanSchwartz,
    presenter3: {},
    cardClass: 'display-none',
    tracking: 'archived, video play, video - narcolepsy bridging the gap'
  }
  const science = {
    title1: 'From Science to Symptoms: A Different Look at Narcolepsy ',
    video: videoLists.archivedEvents[1],
    imageAlt: videoLists.archivedEvents[1].imageAlt,
    date: 'May 2013',
    content1: (
      <div>
        The satellite broadcast event, <i>From Science to Symptoms: A Different Look at Narcolepsy,</i> features
        narcolepsy education presented by 3 of the country's top experts in sleep medicine and viewed simultaneously at
        venues across the country by healthcare professionals in various specialties. Topics covered include the
        neurobiology of sleep and wakefulness, pathophysiology and symptoms of narcolepsy, challenges in diagnosis,
        identifying and screening for narcolepsy, and establishing the diagnosis.
      </div>
    ),
    title2: 'The learning objectives for this program were:',
    content2: (
      <ul>
        <li>
          <span>
            Develop a critical understanding of the multiple factors that affect symptom presentation in patients with
            narcolepsy
          </span>
        </li>
        <li>
          <span>
            Describe the pathophysiology of narcolepsy and integrate basic pathophysiologic concepts with clinical
            reasoning and decision-making
          </span>
        </li>
        <li>
          <span>
            Describe the neurobiologic links among the symptoms of narcolepsy, including excessive daytime sleepiness,
            cataplexy, disturbed nocturnal sleep, hypnagogic hallucinations, and sleep paralysis
          </span>
        </li>
        <li>
          <span>
            Discuss incorporating clinical best practices and screening tools into clinical practice to help improve
            awareness, recognition, screening, and diagnosis of patients with narcolepsy
          </span>
        </li>
      </ul>
    ),
    presenter1: presenters.thomasRoth,
    presenter2: presenters.jonathanSchwartz,
    presenter3: presenters.karlDoghramji,
    tracking: 'archived, video play, video - from science to symptoms'
  }
  return (
    <Layout addedClass='page-archived-events' location={location}>
      <div className="archived-events-page-wrapper">
        <TopLink
          topLinkContent={{
            pageName: 'Education On Demand',
            link: 'Education on Demand',
            tracking: 'archived, click, educaton on demand button'
          }}
        />
        <div className="header-content">
          <div className="headers">
            <h1>Archived Narcolepsy Link Satellite Broadcast Events</h1>
            <p>
              These educational programs—streamed live throughout the United States—are designed for sleep specialists,
              neurologists, pulmonologists, psychiatrists, and primary care physicians who may have patients with
              narcolepsy. The presentations cover the clinical issues associated with the recognition, screening, and
              diagnosis of patients with narcolepsy.
            </p>
          </div>
          <img src={narcolepsyBrainImg} alt="Narcolepsy pathophysiology and the neurobiology of wakefulness " />
        </div>
        <div className="section-gradient" />
        <section className="archived-event-card-section primary-container">
          <ArchivedEventCard
            archivedCardContent={bridging}
            videoClass={videoClass}
            videoTitleColon={videoTitleColon}
            lookDeeperTracking={'archived, click,	look deeper - from science to symptoms'}
            location={location}
          />
        </section>
        <div className="section-gradient" />
        <section className="archived-event-card-section primary-container">
          <ArchivedEventCard
            archivedCardContent={science}
            videoClass={videoClass}
            videoTitleColon={videoTitleColon}
            lookDeeperTracking={'archived, click, look deeper - from science to symptoms'}
            location={location}
          />
        </section>
      </div>
    </Layout>
  )
}

export default ArchivedEvents
