import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { trackGa } from '../GoogleTracking'
import './video-player-detail.component.scss'
import BrightCoveVideoComponent from '../brightcove-video/brightcove-video.component'

import queryString from 'query-string'

class VideoPlayerDetailComponent extends Component {
  constructor(props) {
    super(props)
  console.log('query string', queryString)

  }
  render() {
    this.determineAutoPlay()
    return (
      <div className="video-player-detail-wrapper">
        <BrightCoveVideoComponent
          videoId={this.props.video.videoId}
          player={this.props.video.player}
          account={this.props.video.account}
          // autoPlay={this.state.autoPlayVideos}
          videos={this.props.videos}
          // TO DO
          // autoPlayVideos={this.props.autoPlayVideos}
          nextVideoSelected={this.props.nextVideoSelected}
          tracking={this.props.video.tracking}
          liveRampTracking={this.props.video.liveRampTracking}
          trackPercentComplete={this.props.trackPercentComplete}
          dataTrackPlayTime={this.props.video.trackPlayTime}
          dataTrack25={this.props.video.isPast25}
          dataTrack50={this.props.video.isPast50}
          dataTrack75={this.props.video.isPast75}
          dataTrack100={this.props.video.is100Complete}
          src={this.props.video.src}
        />
        <div className="content">
          <h2 className="title">
            {this.props.video.title}
            {this.props.videoTitleColon.colon}
          </h2>
          <h2 className="sub-title">{this.props.video.subTitle}</h2>
          <p className={this.quoteClass()}>&ldquo;{this.props.video.quote}&rdquo;</p>
          <div className={this.props.videoClass.description}>{this.props.video.description}</div>
        </div>
      </div>
    )
  }

  /**
   *  Determines whether to autoplay the current video based on whether its
   *  ID # is in the query string of the URL. (aka user is accessing an anchor
   *  link)
   */

  // TO DO: uncomment this func and fix error
  determineAutoPlay(){
    // console.log('props', props)
    // let params =  queryString.parse(this.props.location.search)
    // this.state = {
    //   autoPlayVideos: params['videoid'] === this.props.video.videoId
    // }
  }

  quoteClass() {
    return this.props.video.quote ? 'quote' : 'quote-gone'
  }

  googleTracking(tracking) {
    trackGa(tracking)
  }
}

const { object, bool, func } = PropTypes

VideoPlayerDetailComponent.propTypes = {
  video: object.isRequired,
  videoClass: object,
  videoTitleColon: object,
  videos: PropTypes.array,
  autoPlayVideos: bool,
  nextVideoSelected: func,
  trackPercentComplete: bool
}

VideoPlayerDetailComponent.propTypes = {
  match: PropTypes.object.isRequired
}
export default VideoPlayerDetailComponent
